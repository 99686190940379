<script lang="ts">
    import type { Snippet } from 'svelte'
    import type { HTMLAttributes } from 'svelte/elements'

    interface Props extends HTMLAttributes<HTMLPictureElement> {
        alt?: string
        external?: boolean
        height: number
        lazy?: boolean
        role?: string
        src: string
        width: number
        children?: Snippet
    }

    const {
        alt = '',
        external = false,
        height,
        lazy = false,
        role = '',
        src,
        width,
        children,
        ...rest
    }: Props = $props()
</script>

<picture {...rest}>
    {#if children}
        {@render children()}
    {/if}

    <img
        alt={alt || ''}
        {height}
        loading={lazy ? 'lazy' : undefined}
        role={role || (!alt && 'presentation') || undefined}
        src={external ? src : `${import.meta.env.VITE_CLOUDINARY_URL}${src}`}
        {width}
    />
</picture>
